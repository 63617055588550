<!--
 * @Author: 月魂
 * @Date: 2022-11-14 11:44:10
 * @LastEditTime: 2022-11-14 16:49:45
 * @LastEditors: 月魂
 * @Description: 
 * @FilePath: \food-show\src\views\Home.vue
-->
<template>
  <div class="container" ref="box">
    <div class="cont">
      <img src="../assets/index/poster-mobile.png" alt="" class="poster mobile">
      <img src="../assets/index/pc.png" alt="" class="poster pc">
      <div class="intro">
        <!-- 语言切换按钮 -->
        <div class="changeLang">
          <div v-if="lang === 'zh'" @click="changeLang('fr')" class="changeBtn">Fr</div>
          <div v-else @click="changeLang('zh')" class="changeBtn">中文</div>
        </div>
        <div class="intro-cont">
          <div class="des" @click="showIntro">
            <div class="des-cont">{{ this.$t('intro.des')[0] }}</div>
            <van-icon name="arrow-down" size="16" class="showMore" />
          </div>
          <!-- <div class="des" v-for="(des, idx) in this.$t('intro.des')" :key="idx">{{ des }}</div> -->
        </div>
      </div>
      <div class="part">
        <!-- 各版块海报 -->
        <div class="list">
          <img src="../assets/index/san.png" alt="" class="img" @click="jump('/taste')">
          <img src="../assets/index/wu.png" alt="" class="img" @click="jump('/ingredients')">
          <img src="../assets/index/pao.png" alt="" class="img" @click="jump('/stunt')">
          <img src="../assets/index/cang.png" alt="" class="img cang" @click="jump('/utensils')">
          <img src="../assets/index/chuan.png" alt="" class="img" @click="jump('/shop')">
          <img src="../assets/index/wang.png" alt="" class="img" @click="jump('/recipe')">
          <img src="../assets/index/qian.png" alt="" class="img" @click="jump('/teaching')">
        </div>
        <Footer></Footer>
        <!-- <div class="foot">
          <img src="../assets/index/bg.png" alt="" class="img mobile">
          <img src="../assets/index/footer-bg.png" alt="" class="img pc">
          <Footer></Footer>
        </div> -->
      </div>
    </div>
    <div class="home-wrapper">
      <van-overlay :show="show" @click="show = false" :lock-scroll="false">
        <div class="wrapper" @click.stop>
          <div class="show-cont">
            <div class="show-des" v-for="(des, idx) in this.$t('intro.des')" :key="idx">{{ des }}</div>
          </div>
        </div>
      </van-overlay>
    </div>
  </div>
</template>

<script>
import Footer from '../components/Footer.vue'
import Vue from 'vue'
import { Overlay, Icon } from 'vant'

Vue.use(Overlay).use(Icon)

export default {
  name: 'Home',
  components: {
    Footer
  },
  data() {
    return {
      lang: localStorage.getItem('lang') || 'fr',
      show: false
    }
  },
  methods: {
    changeLang(lang) {
      this.$i18n.locale = lang
      this.lang = lang
      localStorage.setItem('lang', lang);
    },
    jump(url) {
      this.$router.push(url)
    },
    showIntro() {
      this.show = true
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  background: #06062b;
  overflow: hidden;
  .cont {
    min-height: 300px;
    position: relative;
    padding-top: 260px;
    @media (min-width: 1000PX) {
      padding-top: 360px;
    }
    .poster {
      display: block;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    .img {
      display: block;
      width: 100%;
    }
    .pc {
      display: none;
    }
    @media (min-width: 1000PX) {
      .mobile {
        display: none;
      }
      .pc {
        display: block;
      }
    }
    .part {
      position: relative;
      margin-top: 24px;
    }
    @media (min-width: 1000PX) {
      & {
        background: url('../assets/index/pc-bg.png') no-repeat;
        background-size: cover;
        z-index: 1;
      }
    }
    .intro {
      width: 100%;
      position: relative;
      .intro-cont {
        width: 100%;
        padding: 0 16px;
        max-width: 1080PX;
        margin: auto;
        cursor: pointer;
        .des {
          background: rgba(0, 0, 0, .5);
          padding: 16px;
          border-radius: 15px;
          font-size: 12px;
          color: #CED3E8;
          text-align: center;
          .des-cont {
            text-align: left;
            text-indent: 2em;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 6;
            -webkit-box-orient: vertical;
          }
          .showMore {
            margin: auto;
            color: #CED3E8;
          }
        }
      }
    }
    .changeLang {
      margin-bottom: 16px;
      @media (min-width: 1000PX) {
        & {
          width: 1080PX;
          margin: auto;
          margin-bottom: 16px;
        }
      }
      overflow: hidden;
      .changeBtn {
        float: right;
        margin-right: 16px;
        min-width: 30px;
        text-align: center;
        line-height: 30px;
        padding: 0 6px;
        font-size: 12px;
        color: #fff;
        border-radius: 5px;
        border: 1px solid #fff;
        letter-spacing: 1px;
      }
    }
    .list {
      @media (min-width: 1000PX) {
        & {
          width: 1080PX;
          margin: auto;
        }
      }
      .img {
        padding: 0 16px;
        display: block;
        width: 100%;
        margin-bottom: 8px;
      }
      .cang {
        padding: 0 16px 0 0;
      }
    }
    .foot {
      position: relative;
    }
  }
  .wrapper {
    width: calc(100% - 32px);
    max-width: 1080PX;
    height: 500px;
    background: rgba(0, 0, 0, .7);
    color: #ccc;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 15px;
    overflow: auto;
    .show-cont {
      width: 100%;
      padding: 16px;
      .show-des {
        font-size: 12px;
        text-indent: 2em;
      }
    }
  }
}
</style>
