<!--
 * @Author: 月魂
 * @Date: 2021-12-06 11:36:00
 * @LastEditTime: 2022-12-14 09:35:07
 * @LastEditors: 月魂
 * @Description: 
 * @FilePath: \food-show\src\components\Footer.vue
-->
<template>
  <div class="footer">
    <!-- <div class="visit">访问量：6666</div> -->
    <div class="part">
      <div class="title">
        <div class="zh">主办:</div>
        <div class="en">Organisateurs: </div>
      </div>
      <div class="item">
        <div class="zh">巴黎中国文化中心</div>
        <div class="en">Centre culturel de Chine à Paris</div>
      </div>
      <div class="item">
        <div class="zh">陕西省文化和旅游厅</div>
        <div class="en">Département de la Culture et du Tourisme de la Province du Shaanxi</div>
      </div>
    </div>
    <div class="part">
      <div class="title">
        <div class="zh">承办: </div>
        <div class="en">Opérateur: </div>
      </div>
      <div class="item">
        <div class="zh">陕西文一国际文化发展有限公司</div>
        <div class="en">Shaanxi Wenyi International Culture Development Co., Ltd</div>
      </div>
    </div>
    <div class="part">
      <div class="title">
        <div class="zh">鸣谢: </div>
        <div class="en">Sponsors</div>
      </div>
      <div class="list">
        <div class="zh" v-for="(name, idx) in sponsor.zh" :key="idx">{{ name }}</div>
      </div>
      <div class="list">
        <div class="en" v-for="(name, idx) in sponsor.fr" :key="idx">{{ name }}</div>
      </div>
    </div>
    <div class="support">{{ this.$t('params.ts') }}: {{ this.$t('params.com') }}</div>
    <div class="visit">Visitors: {{ this.sum }}</div>
    <a href="http://beian.miit.gov.cn/" target="_blank" class="bei">陕ICP备20001779号-1</a>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'Footer',
  data() {
    return {
      sum: 0,
      sponsor: {
        zh: [ '陕西省饮食营养协会', '西安饮食股份有限公司', '陕西西影数码传媒科技有限责任公司', '陕西西咸新区秦汉新城影视发展有限公司', '德懋恭食品有限公司', '西安高陵奇石博物馆', '西安永兴坊文化发展有限公司', '澄城县尧头窑文化旅游生态园区管理委员会', '西安饮食股份有限公司德发长酒店' ],
        fr: [ "Association alimentaire et diététique de la province du Shaanxi Xi'an Catering Co.,Ltd.", "Shaanxi Western Movie Digital Media Science and Technology LLC", "Shaanxi Xixian New Area Film And Television Development Co.,Ltd.", "Demaogong Food Co.,Ltd.", "Musée de pierres rares de Gaoling de la ville de Xi’an", "Xi'an Yongxingfang Culture Development Co., Ltd.", "Comité administratif du Parc écologique de culture et de tourisme de", "Yaotouyao du district de Chengcheng", "Hôtel de Defachang de Xi'an Catering Co., Ltd." ]
      }
    }
  },
  mounted() {
    axios.get('https://silkroad8th.scpece.org.cn:18990/api/v1/3').then(res => {
      this.sum = res.data;
    })
    axios.put('https://silkroad8th.scpece.org.cn:18990/api/v1/3')
  }
}
</script>

<style lang="less" scoped>
.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  padding: 10px;
  text-align: center;
  font-size: 12px;
  color: #CED3E8;
  line-height: 1;
  background: url('../assets/index/bg.png') no-repeat;
  background-size: cover;
  padding: 16px;
  overflow: hidden;
  @media (min-width: 1200PX) {
    & {
      padding: 16px 0;
    }
  }
  .visit {
    width: 100%;
    font-size: 12px;
    text-align: center;
  }
  .part {
    width: 100%;
    color: #CED3E8;
    .zh, .en {
      text-align: center;
      line-height: 1;
      font-size: 12px;
    }
    .en {
      margin-top: 4px;
    }
    .title {
      padding: 8px 0;
      .zh {
        font-size: 14px;
      }
    }
    .item {
      width: 100%;
      padding: 8px 0;
    }
    .list {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      max-width: 1080PX;
      margin: auto;
      .zh, .en {
        padding: 6px;
      }
    }
  }
  .bei {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: #CED3E8;
  }
}
</style>
